<template>
  <v-main :class="$vuetify.theme.dark ? undefined : 'grey lighten-3'">
    <router-view />
  </v-main>
</template>

<script>
export default {
  created() {
    document.title = 'التراث الرقمي';  
  }
};
</script>
